/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from 'react'
import { GetStaticProps, InferGetStaticPropsType } from 'next'

import Head from 'next/head'
import Script from 'next/script'
import dynamic from 'next/dynamic'
// import Image from '../components/Image'
// import { useCollectionQuery } from '../hooks/useCollections'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
// import router from 'next/router'
import {
  CategoryList,
  AboutUs,
  SectionFour,
  // CovetPics,
  // IndexSwiper,
  SizeSection,
  ProductScrollXSection,
  ProductCategorySection,
  OccasionList,
} from '../components/index-section'
import { Item } from '../components/swipers/IndexSwiper'
import { BANNERS } from '../utils/index-config'
import { HomePageSensor } from '../utils/bloomchicdata/page/homepage'
import initialzeApollo from '../api/client'
import {
  CollectionByHandlerQuery,
  CollectionByHandlerQueryVariables,
} from '../../types/storefront'
import ShopQuery from '../api/graphql/shopQuery.gql'
import collectionHandlerQuery from '../api/graphql/collectionHandlerQuery.gql'
import { useIsomorphicLayoutEffect } from '../utils/helpers'
// import InfinityCards from '../components/InfinityCards'

// import Layout from '../components/Layout/layout'

const IndexSwiper = dynamic(() => import('../components/swipers/IndexSwiper'), {
  ssr: false,
  loading: () => <Item data={BANNERS[0]} />,
})

const swiperArr = [
  {
    label: 'New',
    handle: 'new-in-1',
  },
  {
    label: 'Best Sellers',
    handle: 'best-sellers-1',
  },
  {
    label: 'Dresses',
    handle: 'dresses-1',
  },
  {
    label: 'Outerwear',
    handle: 'coats-jackets-1',
  },
]
export const getStaticProps: GetStaticProps = async () => {
  const client = initialzeApollo()
  const res = await client.query<{
    shop: { description: string; name: string }
  }>({
    query: ShopQuery,
  })
  const getCollectionsData = async (handle: string) =>
    client.query<CollectionByHandlerQuery, CollectionByHandlerQueryVariables>({
      query: collectionHandlerQuery,
      variables: {
        handle,
        first: 30,
      },
    })
  const [
    { data: handle0 },
    { data: handle1 },
    { data: handle2 },
    { data: handle3 },
  ] = await Promise.all(swiperArr.map((i) => getCollectionsData(i.handle)))
  return {
    props: {
      ...res.data.shop,
      handle0,
      handle1,
      handle2,
      handle3,
      title: 'BloomChic | Shop Trendy Plus Size Women&#39;s Fashion',
    },
    revalidate: 60 * 60 * 24 * 2,
  }
}

const homePageSensorMod = () => import('../utils/bloomchicdata/page/homepage')

let homePageSensor: HomePageSensor | null
const Index: FC<InferGetStaticPropsType<typeof getStaticProps>> = (props) => {
  const { description, title, ...data } = props
  // const data = { newIn, holidaySale, bestSell, classy }
  useIsomorphicLayoutEffect(() => {
    if (process.browser) {
      homePageSensorMod().then((mod) => {
        homePageSensor = mod.default
        homePageSensor?.Action()
      })
    }
  }, [])
  const theme = useTheme()
  const upMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className="pb-5 md:pb-36">
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={BANNERS[0].image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:site" content="@" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
      </Head>

      <Script
        strategy="lazyOnload"
        src="https://shopify.covet.pics/covet-pics-widget-inject.js"
      />

      <section data-name="顶部轮播图" data-id="20211008-IndexSwiper-1">
        <IndexSwiper />
      </section>

      {/* <section data-name="折扣信息" data-id="20211008-Image-1"> */}
      {/*  <div className="mb-10 md:mb-20"> */}
      {/*    <div className="hidden md:block container"> */}
      {/*      <Image */}
      {/*        src="//cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/2021818-12294.jpg?v=1629274088" */}
      {/*        alt="summer clearance" */}
      {/*        width={1943} */}
      {/*        height={200} */}
      {/*      /> */}
      {/*    </div> */}
      {/*    <div className="md:hidden"> */}
      {/*      <Image */}
      {/*        src="//cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/2021818-122911.jpg?v=1629274104" */}
      {/*        alt="summer clearance" */}
      {/*        width={750} */}
      {/*        height={150} */}
      {/*      /> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </section> */}

      <section data-name="分类区" data-id="20211008-CategoryList-1">
        <CategoryList />
      </section>

      {/* <section
        data-name="New Arrivals Fall"
        data-id="20211008-Container-Image-1"
      >
        <Container className="mt-24">
          <Image
            src="/images/section3-1.jpeg"
            width={1599}
            height={800}
            alt="autumn-outfits"
          />
        </Container>
      </section> */}

      <section data-name="four-image" data-id="20211008-SectionFour-1">
        <SectionFour />
      </section>

      <section data-name="分类区" data-id="20211222-OccasionList-1">
        <OccasionList />
      </section>

      <section data-name="SHOP BY SIZE" data-id="20211113-ShopBySize-1">
        <SizeSection title="Shop By Size" />
      </section>

      {upMd ? (
        <>
          <section data-name="JUST DROPPED" data-id="20211110-Collection-1">
            <ProductScrollXSection data={data?.handle0} title="Just Dropped" />
          </section>

          <section data-name="BEST SELLERS" data-id="20211110-Collection-2">
            <ProductScrollXSection data={data?.handle1} title="Best Sellers" />
          </section>
        </>
      ) : (
        <ProductCategorySection data={data} swiperArr={swiperArr} />
      )}

      {/* <section data-name="three-image" data-id="20211008-SectionFive-1">
        <SectionFive />
      </section> */}

      {/* <section data-name="ins区" data-id="20211008-CovetPics-1"> */}
      {/*  <CovetPics /> */}
      {/* </section> */}

      <section data-name="品牌介绍区" data-id="20211008-AboutUs-1">
        <AboutUs />
      </section>
    </div>
  )
}

// @ts-ignore
// Index.getLayout = function getLayout(page) {
//   return <Layout showDiscount>{page}</Layout>
// }

export default Index
