export const BANNERS = [
  {
    image:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/1920X670_3854a059-990d-4cc2-bc87-c5756fcdf695.jpg?v=1639647286',
    mobileImage:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/750X1000_a45ed532-57ae-42b2-8f44-854d147b3fe3.jpg?v=1639647299',
    title: 'banner1',
    href: '/collections/holiday-sale',
  },
  {
    image:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/e6c50fccc8ba83035c87a3af2f9670b9_5830d418-3e01-44ca-9849-bb8b623b1226.jpg?v=1638787918',
    mobileImage:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/782x712_d9a09233-d8dd-42f3-adab-2665a3cd8559.jpg?v=1638787937',
    title: 'banner2',
    href: '/collections/velvet-2',
  },
]

export const SECTION_TWO = [
  {
    image:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/2021-3-3_4__02.jpg?v=1629706716',
    prefix: 'SUMMER FASHION',
    surffix: 'Update the new and upcoming trends',
    title: 'NEW ARRIVAL',
    buttons: [
      { label: 'TOPS', href: '/collections/tops' },
      { label: 'BOTTOMS', href: '/collections/tops' },
    ],
  },
]

// export const SECTION_THREE = [
//   'dresses-1',
//   'tops-1',
//   'sweatshirts',
//   'bottoms-1',
//   'romper-jumpsuit',
//   'sweaters-cardigans-1',
//   'accessories',
//   'lingerie-loungwear',
// ].map((i, idx) => ({
//   image: imageLoader(`/images/section3-${idx + 1}.jpeg`),
//   title: i,
// }))
export const SECTION_THREE = [
  {
    image:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/collections/2021-3-3_4__02.jpg?v=1640080984',
    title: 'dresses-1',
  },
  {
    image:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/collections/2021-3-3_4__01.jpg?v=1640080985',
    title: 'tops-1',
  },
  {
    image:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/collections/2021-3-3_4__03.jpg?v=1640080987',
    title: 'bottoms-1',
  },
  {
    image:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/collections/436-160_25_3496e864-ca97-4651-891a-9b4c862e81ca.jpg?v=1640080966',
    title: 'coats-jackets-1',
  },
  {
    image:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/collections/Shop-By-Category320X320_06.jpg?v=1640080842',
    title: 'loungewear',
  },
  {
    image:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/collections/Shop-By-Category320X320_05.jpg?v=1640080872',
    title: 'sweaters-cardigans',
  },
  // {
  //   image:
  //     '//cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/436-160_09.jpg?v=1629706855',
  //   title: 'popular-rompers-jumpsuit',
  // },
  // {
  //   image:
  //     '//cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/436-160_23_9a88be80-db2b-4897-9858-65eeddebe775.jpg?v=1629706788',
  //   title: 'sweaters-cardigans-1',
  // },
  // {
  //   image:
  //     '//cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/436-160_25_3496e864-ca97-4651-891a-9b4c862e81ca.jpg?v=1629706874',
  //   title: 'coats-jackets-1',
  // },
  // {
  //   image:
  //     '//cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/436-160_25.jpg?v=1629706897',
  //   title: 'hats-and-accessories',
  // },
]

export const SECTION_FOUR = [
  {
    image:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/files/1400X700_06.jpg?v=1640076218',
    mobileImage:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/files/670X804.jpg?v=1640076218',
    title: 'up-to-70-off',
  },
  {
    image:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/files/1400X700_04.jpg?v=1640076218',
    mobileImage:
      'https://cdn.shopify.com/s/files/1/0510/7171/3450/files/750X900_4a6e2f20-f8e6-44d9-a89b-50bf59f82d39.jpg?v=1640076218',
    title: 'velvet-2',
  },
  {
    image:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/1400X700_05.jpg?v=1640076218',
    mobileImage:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/670X804_173741ff-b0f4-47e7-9221-7c5ba515ec5d.jpg?v=1640076218',
    title: 'loungewear',
  },
  // {
  //   image:
  //     '//cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/20211026_-03.jpg?v=1635216855',
  //   title: 'velvet',
  // },
  // {
  //   image:
  //     '//cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/20211016_-04.jpg?v=1634385026',
  //   title: 'leopard-dresses',
  // },
  // {
  //   image:
  //     '//cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/4-05.jpg?v=1635214174',
  //   title: 'black-dress',
  // },
]
export const SECTION_FIVE = [
  {
    image:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/Shop-by-trend_516x690_01.jpg?v=1640081554',
    title: 'going-out',
  },
  {
    image:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/Shop-by-trend_516x690_03.jpg?v=1640081588',
    title: 'party-2',
  },
  {
    image:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/Shop-by-trend_516x690_02.jpg?v=1640081529',
    title: 'business-1',
  },
  {
    image:
      'https://cdn.shopifycdn.net/s/files/1/0510/7171/3450/collections/Shop-by-trend_516x690_04.jpg?v=1640081576',
    title: 'vacation-1',
  },
]
