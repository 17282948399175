import React, { FC, PropsWithChildren, useContext, useState } from 'react'
import { Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import classnames from 'classnames'
// import dynamic from 'next/dynamic'
import Link from 'next/link'
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdOutlineKeyboardArrowDown,
} from 'react-icons/md'
import Sticky from 'react-stickynode'
import Image from './Image'
import {
  SECTION_FIVE,
  SECTION_FOUR,
  SECTION_THREE,
  SECTION_TWO,
} from '../utils/index-config'
// import { imageLoader } from '../utils/helpers'
import Container from './Container'
import { CollectionByHandlerQuery } from '../../types/storefront'
import Product from './Product'
import 'swiper/css'
import { useIsomorphicLayoutEffect } from '../utils/helpers'
import { ShowDiscount } from './Layout/layout'
import { HomePageSensor } from '../utils/bloomchicdata/page/homepage'

const homePageSensorMod = () => import('../utils/bloomchicdata/page/homepage')
let homePageSensor: HomePageSensor | null

const sizeLists = [
  {
    label: '12 / L',
    handle: '12xl',
  },
  {
    label: '14 / 1X',
    handle: '14-1x-1',
  },
  {
    label: '16 / 1X',
    handle: '16-1x-1',
  },
  {
    label: '18 / 2X',
    handle: '18-2x-1',
  },
  {
    label: '20 / 2X',
    handle: '20-2x-1',
  },
  {
    label: '22 / 3X',
    handle: '22-3x-1',
  },
]

/**
 * Index Components
 */
const SectionHeader: FC<PropsWithChildren<{ className?: string }>> = (
  props
) => {
  const { children, className } = props
  return (
    <h2
      className={classnames([
        'md:text-3xl text-lg font-medium text-center',
        className,
      ])}
    >
      {children}
    </h2>
  )
}

export const SectionOne = () => (
  <section className="lg:container flex items-center text-center text-xl whitespace-nowrap">
    <div className="p-11 flex-1">
      <div className="tracking-widest font-light">UP TO</div>
      <div className="text-7xl font-semibold my-1">
        15
        <sup className="text-4.5xl tracking-normal">%</sup>
        <small className="text-lg uppercase -ml-9">off</small>
      </div>
      <div className="tracking-widest font-light">SUMMER SALE</div>
    </div>
    <Image
      src="/images/section1-1.jpeg"
      width={750}
      height={1000}
      alt="summer sale"
    />
    <Image
      src="/images/section1-2.jpeg"
      width={750}
      height={1000}
      alt="summer sale"
    />
  </section>
)

export const SectionTwo = () => {
  const renderContent = ({
    prefix,
    surffix,
    title,
    buttons,
  }: {
    prefix: string
    surffix: string
    title: string
    buttons: { label: string; href: string }[]
  }) => (
    <div className="absolute top-0 bottom-0 w-full z-10 bg-black bg-opacity-15 text-white flex flex-col justify-center items-center">
      <div className="font-light tracking-wider text-sm">{prefix}</div>
      <div className="text-3xl font-semibold my-2">{title}</div>
      <div className="font-light text-sm">{surffix}</div>
      <div className="mt-3">
        {buttons.map((i, idx) => (
          <a
            className="button button--outline text-sm mx-1"
            href={i.href}
            key={idx}
          >
            {i.label}
          </a>
        ))}
      </div>
    </div>
  )

  return (
    <section className="container leading-0">
      <div className="flex -mx-2.5">
        {SECTION_TWO.map((i) => (
          <div className="px-2.5" key={i.image}>
            <div className="relative">
              <Image
                src={i.image}
                width={1268}
                height={972}
                alt="new arrival"
              />
              {renderContent(i)}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export const CategoryList = () => (
  <Container className="mt-6 md:mt-16 md:mb-0">
    <SectionHeader className="mb-1.5 md:mb-9">Shop By Category</SectionHeader>
    <div className="grid md:grid-cols-6 grid-cols-2 -mx-1.5 md:-mx-2.5">
      {SECTION_THREE.map((i) => (
        <Link
          href={`/collections/${i.title}`}
          key={i.title}
          passHref
          prefetch={false}
        >
          <a className="py-3.5 px-2 md:p-2.5 inline-flex">
            <Image src={i.image} alt={i.title} width={320} height={400} />
          </a>
        </Link>
      ))}
    </div>
  </Container>
)

export const SectionFour = () => (
  <Container className="mt-3 md:mt-14">
    {SECTION_FOUR.map((i) => (
      <Link
        href={`/collections/${i.title}`}
        passHref
        key={i.title}
        prefetch={false}
      >
        <a className="block md:mb-8 mb-2.5">
          {/* <Image
            sizes="720"
            src={i.image}
            width={1280}
            height={455}
            alt="new in"
            key={i.title}
          /> */}
          <div className="hidden md:block">
            <Image
              layout="responsive"
              src={i.image}
              alt={i.title}
              width={1400}
              height={700}
            />
          </div>
          <div className="md:hidden">
            <Image
              layout="responsive"
              src={i.mobileImage}
              width={720}
              height={864}
              alt={i.title}
            />
          </div>{' '}
        </a>
      </Link>
    ))}
  </Container>
)

export const SectionFive = () => (
  <Container className="mt-10 md:mt-10">
    <div className="grid grid-cols-none md:grid-cols-3 -mx-3">
      {Array(3)
        .fill(0)
        .map((_, idx) => (
          <div className="px-3 mb-4 md:mb-0" key={idx}>
            <Image
              src="/images/section4-1.jpeg"
              width={600}
              height={800}
              alt="party"
            />
          </div>
        ))}
    </div>
  </Container>
)

export const OccasionList = () => (
  <Container className="mt-6 md:mt-16 -mb-1.5 md:mb-0">
    <SectionHeader className="mb-1.5 md:mb-9">Shop By Occasion</SectionHeader>
    <div className="grid md:grid-cols-4 grid-cols-2 -mx-1.5 md:-mx-2.5">
      {SECTION_FIVE.map((i) => (
        <Link
          href={`/collections/${i.title}`}
          key={i.title}
          passHref
          prefetch={false}
        >
          <a className="py-3.5 px-2 md:p-2.5 inline-flex">
            <Image src={i.image} alt={i.title} width={320} height={464} />
          </a>
        </Link>
      ))}
    </div>
  </Container>
)

export const SizeSection = (props: { title: string }) => {
  const { title } = props
  return (
    <Container className="mt-4 md:mt-14">
      <SectionHeader className="mb-5 md:mb-8">{title}</SectionHeader>
      <div className="grid grid-cols-3 md:grid-cols-6 gap-x-3 md:gap-x-4 gap-y-3 md:gap-y-4">
        {sizeLists.map((i) => (
          <Link href={`/collections/${i.handle}`} passHref key={i.label}>
            <div className="button bg-white text-black text-xs sm:text-sm md:text-lg text-center py-2 sm:py-3 md:py-4 px-0 border border-black">
              {i.label}
            </div>
          </Link>
        ))}
      </div>
    </Container>
  )
}

// export const SectionSix = () => (
//   <section>
//     <div className="relative overflow-hidden" style={{ height: '650px' }}>
//       <div className="flex justify-center items-center h-full">
//         <div>
//           <Image
//             src={imageLoader('/images/section5-1.jpeg')}
//             alt="new fashion"
//             layout="fill"
//             objectFit="cover"
//           />
//         </div>
//       </div>

//       <div className="absolute top-0 bottom-0 w-full z-10">
//         <div className="container text-white flex items-center h-full">
//           <div className="relative hero__text-shadow">
//             <div className="text-7xl font-semibold">NEW FASHION</div>
//             <div className="text-lg">explore now</div>
//           </div>
//         </div>
//       </div>
//     </div>

//     <div className="text-center mt-10">
//       <Link href="/">
//         <a className="button bg-black text-white text-sm py-3">VIEW ALL</a>
//       </Link>
//     </div>
//   </section>
// )

export const ProductSection = (props: {
  data?: CollectionByHandlerQuery
  title: string
}) => {
  const { data, title } = props
  return (
    <Container className="mt-10 md:mt-12">
      <Link
        href={`/collections/${data?.collection?.handle}`}
        passHref
        prefetch={false}
      >
        <a>
          <SectionHeader className="mb-4 md:mb-8">{title}</SectionHeader>
        </a>
      </Link>

      {data && (
        <div className="grid grid-cols-2 md:grid-cols-5 gap-x-3 md:gap-x-4">
          {data.collection?.products.edges.slice(0, 10).map((i, index) => (
            <Product
              className="mb-6"
              collectionTitle={data.collection?.title || ''}
              data={i.node}
              key={i.node.id}
              productIndex={index + 1}
              hiddenRating
            />
          ))}
        </div>
      )}
      <div className="text-center mt-4">
        <Link
          href={`/collections/${data?.collection?.handle}`}
          passHref
          prefetch={false}
        >
          <a className="button text-white bg-black text-tiny sm:text-sm md:py-3 md:px-6 font-semibold tracking-normal tracking-widest">
            VIEW ALL
          </a>
        </Link>
      </div>
    </Container>
  )
}

export const InfinityCardsMobileSection = (props: {
  data?: CollectionByHandlerQuery
}) => {
  const { data } = props
  const total = data?.collection?.products.edges?.length || 0
  const [page, setPage] = useState(1)
  const fetchMore = () => {
    setPage(page + 1)
  }
  return (
    <Container className="mt-2">
      {data && (
        <div className="grid grid-cols-2 gap-x-3">
          {data.collection?.products.edges
            .slice(0, 10 * page)
            .map((i, index) => (
              <Product
                className="mb-6"
                collectionTitle={data.collection?.title || ''}
                data={i.node}
                key={i.node.id}
                productIndex={index + 1}
                hiddenRating
              />
            ))}
        </div>
      )}
      <div className="text-center">
        {page > 3 || total <= page * 10 ? (
          <Link
            href={`/collections/${data?.collection?.handle}`}
            passHref
            prefetch={false}
          >
            <a className="button text-white bg-black text-tiny sm:text-sm md:py-3 md:px-6 font-semibold tracking-normal tracking-widest">
              VIEW ALL
            </a>
          </Link>
        ) : (
          <div
            className="absolute bottom-0 left-0 w-full flex justify-center items-center pt-7 pb-2 text-center text-xss cursor-pointer"
            style={{
              backgroundImage:
                'linear-gradient(0deg,rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 1) 60%,rgba(255, 255, 255, 0) 100%)',
            }}
            onClick={() => fetchMore()}
          >
            Load More
            <MdOutlineKeyboardArrowDown color="inherit" size={18} />
          </div>
        )}
      </div>
    </Container>
  )
}

export const ProductScrollXSection = (props: {
  data?: CollectionByHandlerQuery
  title: string
}) => {
  const { data, title } = props
  let randomText = ''
  for (let i = 0; i < 10; i += 1) {
    randomText += String.fromCharCode(Math.floor(Math.random() * 26 + 65))
  }

  return (
    <Container className="relative mt-10 md:mt-24">
      <div className="flex justify-between text-center mt-4">
        <Link
          href={`/collections/${data?.collection?.handle}`}
          passHref
          prefetch={false}
        >
          <a>
            <SectionHeader className="mb-4 md:mb-8 md:text-3xl">
              {title}
            </SectionHeader>
          </a>
        </Link>
        <Link
          href={`/collections/${data?.collection?.handle}`}
          passHref
          prefetch={false}
        >
          <a className="text-black text-tiny sm:text-lg md:py-3 md:px-6 font-semibold tracking-normal underline">
            View More
          </a>
        </Link>
      </div>

      <div className="relative">
        <div
          className="absolute left-0 right-0"
          style={{ paddingTop: 'calc((100% - (16 * 4px)) / 5 * 4 / 3 / 2 )' }}
        >
          <button
            type="button"
            className={`${randomText}-swiper-btn-prev absolute left-0 z-10 transform -translate-y-1/2 -translate-x-1/2 border-1 border-gray-400 hover:border-black border-solid rounded-full w-11 h-11 flex justify-center items-center text-gray-400 hover:text-black bg-white hover:bg-gray-50`}
            style={{ top: `100%` }}
          >
            <MdOutlineArrowBackIos color="inherit" fontSize="18" />
          </button>
          <button
            type="button"
            className={`${randomText}-swiper-btn-next absolute right-0 z-10 transform -translate-y-1/2 translate-x-1/2 border-1 border-gray-400 hover:border-black border-solid rounded-full w-11 h-11 flex justify-center items-center text-gray-400 hover:text-black bg-white hover:bg-gray-50`}
          >
            <MdOutlineArrowForwardIos color="inherit" fontSize="18" />
          </button>
        </div>
        <Swiper
          modules={[Mousewheel, Navigation]}
          slidesPerView={5}
          spaceBetween={16}
          slidesPerGroup={5}
          loop
          loopFillGroupWithBlank
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: `.${randomText}-swiper-btn-prev`,
            nextEl: `.${randomText}-swiper-btn-next`,
          }}
          direction="horizontal"
          mousewheel={{ forceToAxis: true }}
          className="mySwiper"
          threshold={100}
        >
          {data &&
            data.collection?.products.edges.slice(0, 20).map((i, index) => (
              <SwiperSlide style={{ padding: '0 0.5px' }} key={index}>
                <Product
                  className="mb-6"
                  collectionTitle={data.collection?.title || ''}
                  data={i.node}
                  key={i.node.id}
                  productIndex={index + 1}
                  hiddenRating
                  moduleName={title
                    .toLowerCase()
                    .replace(/^./, title[0].toUpperCase())}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </Container>
  )
}

export const ProductCategorySection = (props: {
  data?: any
  swiperArr: any[]
}) => {
  const { data, swiperArr } = props
  const showDiscount = useContext(ShowDiscount)

  const [swiper, setSwiper] = useState(null)
  const [swiperActive, setSwiperActive] = useState(0)
  const slideTo = (index: number) => {
    setSwiperActive(index)
    // @ts-ignore
    swiper.slideTo(index)
    if (
      (document?.querySelector('#home-tabs-wrapper')?.getBoundingClientRect()
        ?.top || 0) > 0
    )
      return
    document.querySelector('#home-tabs-wrapper')?.scrollIntoView()
  }
  useIsomorphicLayoutEffect(() => {
    if (process.browser) {
      homePageSensorMod().then((mod) => {
        homePageSensor = mod.default
      })
    }
  }, [])

  return (
    <div className="bg-white pt-7 relative">
      <div id="home-tabs-wrapper" className="absolute -top-16" />
      <Sticky
        innerClass="z-20"
        top={showDiscount ? 88 : 0}
        bottomBoundary=".home-sticky-end"
      >
        <div className="grid grid-cols-4 gap-x-3 md:gap-x-4 gap-y-3 md:gap-y-4 py-0.5 bg-white">
          {swiperArr.map((i, k) => (
            <div
              key={`size-${i.label}`}
              onClick={() => {
                slideTo(k)
                homePageSensor?.ResourcesShow(
                  document.getElementsByTagName('section'),
                  false
                )
              }}
              className="flex justify-center bg-white text-black text-xss text-center py-1 sm:py-2 px-0 leading-relaxed cursor-pointer"
            >
              <div
                className={`transform translate-y-1.5 pb-2 border-black ${
                  swiperActive === k && 'font-bold border-b-2'
                }`}
              >
                {i.label}
              </div>
            </div>
          ))}
        </div>
      </Sticky>
      <Swiper
        // @ts-ignore
        onSwiper={setSwiper}
        onSlideChangeTransitionEnd={(e) => setSwiperActive(e.activeIndex)}
        className="home-tabs-container"
      >
        {swiperArr.map((i, k) => (
          <SwiperSlide key={i.label}>
            <section data-name={i.label} data-id={`SwiperSlide-${i.label}`}>
              <InfinityCardsMobileSection data={data[`handle${k}`]} />
            </section>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="home-sticky-end" />
    </div>
  )
}

export const AboutUs = () => (
  <Container className="mt-10 md:mt-24">
    <div className="md:flex md:items-center 2xl:px-32">
      <div className="hidden md:block text-center mr-16 md:mr-8 mb-2 md:mb-0">
        <Image
          src="//cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/3_e3a2af07-b256-4391-9adc-b26e0d93f4af.jpg?v=1625491354"
          width="500"
          height="261"
          alt="about bloomchic"
        />
      </div>
      <div className="text-left sm:text-center md:text-left w-full sm:w-126 mx-auto">
        <h5 className="text-15 font-semibold mt-4 mb-2 md:mb-3">About Us</h5>
        <p className="text-13 md:text-15 mb-6 lg:leading-relaxed">
          At BloomChic, we believe that all women deserve to look and feel
          absolutely beautiful. We&apos;re striving to bring the most on-trend
          and stylish pieces in sizes 12-20, and we&apos;re reimagining this
          space and creating a customer-centric shopping destination that always
          puts fashion first.
        </p>
        <Link href="/blogs/new1/press" prefetch={false}>
          <a>
            <div className="block md:hidden text-center md:mr-14 lg:mr-28 mb-2 md:mb-0">
              <Image
                src="//cdn.shopifycdn.net/s/files/1/0510/7171/3450/files/logo1.jpg?v=1635861490"
                width="1600"
                height="125"
                alt="about bloomchic"
              />
            </div>
            <div className="hidden md:inline-block button text-white bg-black text-tiny sm:text-sm md:py-3 md:px-6 font-semibold tracking-widest">
              LEARN MORE
            </div>
          </a>
        </Link>
      </div>
    </div>
  </Container>
)

export const CovetPics = () => (
  <div className="mt-10 md:mt-24">
    <h2 className="text-md md:text-3xl font-semibold text-center mb-8">
      SHOP OUR INSTAGRAM
    </h2>
    <h4 className="text-center italic mb-10 md:text-lg font-normal">
      Click on an image to shop products
    </h4>

    {/* @ts-ignore */}
    <covet-pics-widget gallery-embed-id="182934" />
  </div>
)
