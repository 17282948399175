import React from 'react'
import Link from 'next/link'
import { Autoplay, Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from '../Image'
import { BANNERS } from '../../utils/index-config'

export const Item = ({ data }: { data: typeof BANNERS[0] }) => (
  <Link href={data.href as string} passHref key={data.href} prefetch={false}>
    <a className="cursor-pointer">
      <div className="hidden md:block">
        <Image
          layout="responsive"
          src={data.image}
          alt={data.title}
          width={2560}
          height={893}
        />
      </div>
      <div className="md:hidden">
        <Image
          layout="responsive"
          src={data.mobileImage}
          width={528}
          height={704}
          alt={data.title}
        />
      </div>{' '}
    </a>
  </Link>
)

const IndexSwiper = () => (
  <Swiper modules={[Autoplay, Mousewheel, Navigation]} autoplay loop>
    {BANNERS.map((i) => (
      <SwiperSlide key={i.title}>
        <Item data={i} />
      </SwiperSlide>
    ))}
  </Swiper>
)

export default IndexSwiper
